import React, { useState } from 'react';
import { Modal } from 'antd';

interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: (inputValue: string) => void;
  title?: string;
  newWhiteBoardTitle: string; // Add prop for title
  setNewWhiteBoardTitle: (title: string) => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  onClose,
  onSave,
  title,
  newWhiteBoardTitle,
  setNewWhiteBoardTitle
}) => {
  const handleSave = () => {
    onSave(newWhiteBoardTitle);
    setNewWhiteBoardTitle('');
    onClose();
  };

  return (
    <Modal visible={visible} className="!z-[10000] " footer={null} centered closable={false}>
      <div className="p-4">
        <h2 className="text-dark-text text-2xl mb-4">{title}</h2>
        <input
          type="text"
          placeholder=""
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              handleSave();
            }
          }}
          value={newWhiteBoardTitle}
          onChange={(e) => setNewWhiteBoardTitle(e.target.value)}
          className="w-full mb-6 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          style={{
            backgroundColor: '#313131',
            borderColor: '#7D7D7D',
            color: '#FFFFFF'
          }}
        />
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <button
            onClick={handleSave}
            className="w-full sm:w-1/2 h-[51px] rounded-lg border border-primary bg-primary text-light-background font-semibold text-[18px]"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="w-full sm:w-1/2 h-[51px] rounded-lg border border-danger text-danger hover:bg-danger-50 text-[18px] font-semibold"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
